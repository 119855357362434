#home .wrapper, #home .carousel{
    position: relative;
    height: 90vh;
    max-height: 900px;
    max-width: 100%;
    background: #333333;
    box-shadow: 0 0 25px var(--secondary-highlight-color);
}

#home .carousel .slide>div{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home .carousel .slider-wrapper {
    height: 100%;
}

#home .carousel .slider {
    height: 100%;
    position: relative;
}
#home .carousel img{
    z-index: 5;
    flex: 1;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    filter: brightness(75%);
}

#home .carousel .bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: fill;
    filter: blur(5px) brightness(25%);
}


#home .banner-container{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

#home .image-container{
    max-height: 200px;
    padding-left: 3px;
}

#home .carousel .control-dots, #home .carousel .control-arrow{
    z-index: 11;
}

#home .banner-container h1 {
    font-size: 128px;
    font-weight: normal;
    margin: 0;
    text-shadow: 3px 3px 5px black;
}

#home .banner-container h2 {
    font-size: 35px;
    font-weight: lighter;
    margin: 0;
    text-shadow: 3px 3px 5px black;
}

#home{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:70px;
}

#home .panel{
    margin: 0 50px;
    max-width: 1200px;
    display: flex;
    border-radius: 25px;
    background-color: rgba(255,255,255,0.9);
    border: 4px solid var(--main-highlight-color);
    box-shadow: 0 4px 24px 8px #009ACC;
    flex-direction: column;
    padding:5vw;
    gap:25px;
}

#home .panel h3{
    color: var(--main-highlight-color);
    font-size: 3rem;
    font-weight: 700;
    margin:0;
}

#home .panel p{
    font-size: 1.5rem;
    margin: 0;
}

@media (max-width: 500px) {

    #home .panel {
        margin: 0 15px;
    }

    #home .panel h3{
        font-size: 2.5rem;
    }

    #home .panel p{
        font-size: 1rem;
    }
    #home .wrapper, #home .carousel {
        height: 65vh;
    }

    #home .image-container{
        max-height: 175px;
    }
}