footer{
    display: flex;
    flex-shrink:1;
    flex-grow: 0;
    flex-basis: 100px;

    flex-direction: column;
}

footer a{
    text-decoration: none;
    color: white;
    flex:1;
}

footer .redes {
    background-color: var(--main-highlight-color);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding:10px;
    border-color: black;
    border-style: solid;
    border-width: 15px 0;
}

footer .redes p{
    padding: 0;
    margin: 0;
    color: white;
    font-size: 1.5rem;
    font-weight: bolder;
}

footer .redes .image-container{
    height: 50px;
}

footer .redes img{
    border-radius: 5px;
}

footer #info {
    flex:1;
    display: flex;
    flex-direction: row;
    padding:25px;
    gap:25px;
    flex-wrap: wrap;
}

footer #info .column {
    flex:1;
    display: flex;
    max-height: 100%;
    border-radius: 25px;
    padding:25px;
    color:white;
    justify-content: center;
    align-items: center;
}

footer #info .column.left {
    flex-direction: row;
    justify-content: flex-start;
    gap:15px;
}

footer #info .column.left a{
    max-width: 100px;
}
footer #info .column.left p{
    text-align: left;
}

footer #info img {
    max-height: 100px;
}
footer ul {
    flex:1;
    display: flex;
    flex-direction: column;
    gap:15px;
    margin:0;
    padding: 0;
}

footer li {
    list-style: none;
    text-align: right;
}
footer p {
    flex:1;
    text-align: center;
}

@media (max-width: 500px) {
    footer li {
        list-style: none;
        text-align: left;
    }
}