.mainCard{
    background-color: #F3F3F3;
    border-radius: 15px;
    margin: 25px;
    padding: 30px;
    text-align: start;
}

.mainCard h1 {
    color: var(--main-highlight-color);
    margin-bottom: 15px;
    font-size: 2.5rem;
    margin-top: 0;
}

.mainCard h2{
    color: var(--secondary-highlight-color);
    font-size: 1.2rem;
}

.mainCard h2::before {
    display: block;
    content: " ";
    margin-top: -100px;
    height: 100px;
    visibility: hidden;
    pointer-events: none;
}

.mainCard h3{
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--tertiary-highlight-color);
}

.mainCard .align-right{
    text-align: right;
}

.mainCard .align-center{
    text-align: center;
}