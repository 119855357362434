#listscroll{
    flex: 1;
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin: 25px;
    padding: 25px;
    border-radius: 25px;
}

.nav-wrapper{
    list-style-type: none;
    position: sticky;
    top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    align-items: flex-start;
    max-width: 25%;
    gap: 15px;
    margin: 10px;
    padding: 0;
    height: fit-content;
}

.nav-wrapper * {
    text-decoration: none;
    font-weight: bolder;
}

.section-wrapper{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    align-items: flex-start;
    font-size: 2rem;
    border-left: 1px solid grey;
}

#listscroll section{
    padding-left: 15px;
}


#listscroll section h1, #archive section h2,
#archive section h3, #archive section h4{
    margin: 0;
    padding: 0;
    scroll-margin-top: 5rem;
}

#listscroll section ol{
    margin: 10px 0;
}

#listscroll section h1{
    color: var(--main-highlight-color);
    margin-bottom: 15px;
    font-size: 2rem;
    margin-top: 30px;
}

#listscroll #section-0 h1 {
    margin-top: 0;
}

#listscroll section h2{
    color: var(--secondary-highlight-color);
    font-size: 1.2rem;
}

#listscroll section h3{
    color: var(--tertiary-highlight-color);
    font-size: 1.1rem;
}

#listscroll section h4{
    font-size: 1.1rem;
}

#listscroll section p{
    font-size: 1rem;
}

#listscroll section li{
    font-size: 1rem;
    margin-top:5px;
    margin-bottom:5px;
}

#listscroll section ul{
    margin-top: 0;
    margin-bottom: 0;
}

#listscroll section img {
    max-width: 100%;
    max-height: 500px;
}

#listscroll section > a, #listscroll section .no_link  {
    display: block;
    font-size: 1.2rem;
    margin-top: 5px;
}

@media (max-width: 500px) {
    #listscroll{
        margin: 5px;
        padding: 10px 5px;
    }

    #listscroll section p {
        font-size:12px;
    }

    #listscroll section{
        padding: 0 5px;

    }

    #listscroll section h1{
        font-size: 22px;
    }

    #listscroll section h2{
        font-size: 16px;
    }

    #listscroll section h3{
        font-size: 14px;
    }

    #listscroll section img{
        width:100%;
        height:auto;
    }

    #listscroll section li {
        font-size: 12px;
    }

    #listscroll section a {
        font-size: 14px;
    }

    .nav-wrapper{
        font-size: 12px;
        max-width: 35%;
        gap:10px;
        top:140px;
    }
}