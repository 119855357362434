header {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    max-height: 75px;
    background-color: white;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    border-bottom: solid 2px var(--main-highlight-color);
    z-index: 1000;
}

header .left-side{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    flex: 1 1 auto;
    gap:10px;
    margin:10px;
}

header .left-side p {
    flex: 1 1 auto;;
    text-align: start;
}

header .image-container{
    flex: 1 1 auto;;
    max-width: 60px;
}

nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 auto;;
    padding-right: 25px;
}

nav a {
    text-decoration: none;
    color: var(--main-text-color);
}

nav ul {
    display: flex;
    list-style-type: none;
    gap: 25px;
    height: 100%;
    align-items: center;
    margin: 0;
    padding: 0;
}

nav li {
    display: flex;
    justify-content: center;
    align-items: center;

}

nav span:hover{
    background-color: #009ACC;
    color: white;
    border-radius: 15px;
}

nav .active{
    background-color: #009ACC;
    color: white;
    border-radius: 15px;
}

nav span{
    padding:10px;
    color: inherit;
    height: inherit;
}

@media (max-width: 500px) {
    header {
        display: flex;
        background-color: white;
        max-height: none;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;
    }
    header .image-container{
        max-height: 60px;
    }

    nav{
        justify-content: space-evenly;
        padding-right: 0;
        min-height: 40px;
        padding-bottom: 5px;
    }

    nav ul {
        gap:5px;
    }
}