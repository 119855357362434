.card{
    border: 1px solid black;
    border-radius: 20px;
    box-shadow: 0px 0px 3px 3px #33333333;
    background-color: #F3F3F3;
    padding: 15px;
    opacity: 0.9;
    transition: all 0.2s ease-in-out;
}

.card:hover {
    opacity:1;
    box-shadow: 0 0 3px 3px #33333399;
    cursor: pointer;
}
.card h2 {
    margin:0
}

section .list {
    display:flex;
    flex-direction: column;
    gap: 15px;
}